/* INICIO Para modificar los colores principales de la aplicación */

/* Dark RED */
$primary-color: #66182a;

/* Falck RED */
$secundary-color: #ff1e2d;
$ternary-color: #ffffff;
$principal-gray: #4c4c4b; //4C4C4B
$line-gray: #e7e7ef;
$link-color: #ff1e2d;
$color-menu: #ffffff;
$color-icon-menu: #fc4450; //confirmar color
$color-tittle: #4c4c4c;
$light-color: #ffaab0; //confirmar color
//Button Styles
$primary-normal-button-color: #FF1E2D;
$primary-hover-button-color: #CC1824;

$secundary-normal-button-color: #FF1E2D;
$secundary-hover-button-color: #CC1824;
//General Styles
$opacity: 0.4;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$falck-primary: mat.define-palette(mat.$indigo-palette);
$falck-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$falck-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$falck-theme: mat.define-light-theme((color: (primary: $falck-primary, accent: $falck-accent, warn: $falck-warn, ), ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($falck-theme);

/* You can add global styles to this file, and also import other style files */